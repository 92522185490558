.navbar-brand img {
  width: 100px;
}
.nav-link {
  color: #191919 !important;
  display: block;
  letter-spacing: 0;
  opacity: 0.7;
  text-decoration: none;
  transition: ease 0.4s all;
  font-family: "Untitled sans", sans-serif;
  text-transform: capitalize;
  padding: .5rem 2rem;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.nav-item i:hover,
.nav-link:hover {
  font-size: medium;
  color: #dc4125 !important;
}
.nav-item i {
  color: #dc4125;
}
.navbar {
  position: fixed;
  padding: 14px 19px;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(222, 137, 49, 1);
  -moz-box-shadow: 0px 1px 5px 0px rgba(222, 137, 49, 1);
  box-shadow: 0px 1px 5px 0px rgba(222, 137, 49, 1);
}
