html {
  scroll-behavior: smooth;
}
.paragraph-41,
.heading-41,
.paragraph-51 {
  color: #5f3913;
}
a:hover {
  color: inherit;
}
.section-41 {
  background-color: #5f39134f;
}
.div-block-40 {
  background-image: linear-gradient(180deg, #5f39134f 75%, #5f39134f 0);
}
.section-36 {
  background-attachment: fixed;
  background-color: #e0902df0;
}
.header {
  background-attachment: fixed;
  background-color: #e0902df0;
  min-height: 40vh;
  margin-top: 10vh;
}
.section_career {
  position: relative;
  display: block;
  width: 100%;
  height: 260px;
  padding: 90px 20px;
  align-items: center;
  background: transparent !important;
}
.my-100 {
  margin-top: 120px;
  margin-bottom: 120px;
}
textarea.form-control {
  height: 200px;
}
.contact-form {
  background: #f8f4f0;
  padding: 3rem;
}
@media (max-width: 770px) {
  .sm-mt {
    margin-top: 1.4rem;
  }
  .contact-form {
    padding: 3rem 1.5rem;
  }
}
.btn,
.button-4,
.button-3 {
  border-radius: 25px !important;
  font-weight: 500 !important;
}
a {
  text-decoration: none;
}
.font30 {
  font-size: 30px;
}
.footer_image.u-mb-small {
  background: url("assets/img/logo-full_alt.png") no-repeat;
  background-size: contain;
}
.div-block-33 {
  background-image: url("./assets/img/banner.png");
}
@media screen and (max-width: 991px) {
  .div-block-33 {
    background-image: url("./assets/img/banner.png");
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 767px) {
  .div-block-33 {
    padding: 58px 40px 40px;
    /* background-image: none; */
  }
}
