.w-container {
  max-width: 1200px;
}

.hbspt-form input {
  height: 40px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 3px;
  border: 1px solid;
  padding: 0 15px;
}

.hbspt-form textarea {
  min-height: 100px;
  width: 100%;
  border: 1px solid;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 0 15px;
}

.hbspt-form label {
  margin-top: 15px;
  font-weight: normal;
}

.hbspt-form select {
  width: 100%;
  height: 40px;
  background: #fff;
  margin-bottom: 15px;
  border: 1px solid;
  padding: 0 15px;
}

.hbspt-form .hs-button {
  padding-right: 40px;
  padding-left: 40px;
  border-radius: 3.4px;
  background-color: hsla(40.23121387283237, 72.38%, 53.14%, 1.00);
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-family: VerbBold, sans-serif;
  font-size: 14px;
  line-height: 2em;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.form-container {
  background: #f8f4ef;
}

.w-embed.w-script {
  width: 100%;
  padding: 24px;
  padding-right: 0;
}

.hs-dependent-field {
  width: 50%;
  float: left;
}

.hbspt-form form {
  display: flex;
  flex-wrap: wrap;
}

.hs-form-field {
  flex: 1 1 40%;
  align-self: stretch;
  margin-right: 24px;
}

.hbspt-form textarea {
  min-height: 40px;
}

.hs_submit.hs-submit {
  width: 100%;
  text-align: center;
}

.hbspt-form .hs-button.primary {
  background-color: #DC5A26;
  color: #fff;
  padding: 0 80px;
  width: auto;
  height: 50px;
  margin-top: 60px;
  margin-bottom: 60px;
  text-transform: none;
  font-size: 22px;
  font-family: 'Untitled sans', sans-serif;
}

.hs_user_type {
  order: 1;
}

.hs_company {
  order: 2;
}

.hs_firstname {
  order: 3;
}

.hs_lastname {
  order: 4;
}

.hs_jobtitle {
  order: 5;
}

.hs_email {
  order: 6;
}

.hs_phone {
  order: 7;
}

.hs_message {
  order: 8;
}

.hs_submit {
  order: 9;
}

.hs-error-msgs {
  list-style: none;
  padding: 0;
  margin-top: -20px;
  font-size: 14px;
  color: #DC5A26;
}

.w--open .menu_burger-icon.w-icon-nav-menu {
  color: transparent;
}

.white {
  color: #fff;
}

.slide-nav-2 .w-active {
  background-color: #035752 !Important;
}

.slide-nav-2 .w-slider-dot {
  background: rgba(3, 87, 82, 0.2) !Important;
}

@media (max-width: 767px) {
  .hs-form-field {
      flex: 1 1 100%;
  }
  .hs-dependent-field {
      width: 100%;
  }
  .nav_link {
      border: none;
  }
}


/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media screen and (max-width: 650px) {
  .nav-menu-5 {
      height: 700px;
      max-height: 700px;
      width: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
  }
}

.paragraph-59 a:first-child {
  transition: all ease 0.5s;
}

.paragraph-59:hover a:first-child {
  color: #DEA531 !important;
}

.link-text {
  color: #191919 !important;
  font-weight: 300 !important;
}

@media screen and (max-width: 500px) {
  .nav-menu-5 {
      height: 450px;
      max-height: 450px;
      width: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
  }
}

@media screen and (max-width: 400px) {
  .nav-menu-5 {
      height: 350px;
      max-height: 350px;
      width: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
  }
}

.w--open img {
  opacity: 0;
}

.nav-hover {
  transition: all ease 0.5s;
}

.nav-hover:hover {
  color: #DEA531;
}

@media (max-width: 991px) {
  .navbar-5 {
      max-width: 100%;
  }
  .nav-menu-5-2 {
      border-top: 1px solid rgb(216, 216, 216);
      border-bottom: 1px solid rgb(216, 216, 216);
  }
}

h1 {
  vertical-align: top;
  margin: 0;
}

.word {
  position: absolute;
  width: 80px;
  text-align: center;
  left: 0;
  opacity: 0;
  top: -33px;
}

.letter {
  display: inline-block;
  position: relative;
  transform: translateZ(25px);
  transform-origin: 50% 50% 25px;
}

.letter.out {
  transform: rotateX(90deg);
  transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.letter.behind {
  transform: rotateX(-90deg);
}

.letter.in {
  transform: rotateX(0deg);
  transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.anim-wrapper {
  height: 32px;
  width: 80px;
  position: relative;
  display: inline-block;
  top: 13px;
  text-align: center;
}

.w-embed.w-script {
  padding: 0;
  padding-right: 24px;
}

@media only screen and (max-width: 442px) {
  .anim-wrapper {
      height: 16px;
      width: 70px !important;
      top: 12px !important;
  }
  .word {
      width: 60px;
  }
}

@media only screen and (max-width: 460px) {
  .anim-wrapper {
      width: 60px !important;
      top: 12px !important;
  }
  .seconds {
      display: inline-block;
      margin-left: -6px;
  }
}

@media only screen and (max-width: 479px) {
  .anim-wrapper {
      height: 16px;
      width: 60px;
      top: 12px !important;
  }
  .seconds {
      display: inline-block;
      margin-left: -6px !important;
  }
  .word {
      width: 60px;
  }
  .heading_h1.white {
      color: white;
      font-size: 42px;
  }
  .div-block-7 {
      padding-left: 0;
  }
}

@media screen and (max-width: 991px) {
  .anim-wrapper {
      top: 30px;
  }
  .word {
      width: 52px;
  }
  .seconds {
      display: inline-block;
      margin-left: -24px;
  }
}

.link-box-arrow {
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-left: 0px;
  border-bottom: 2px none #036967;
  color: #036967;
  font-size: 22px;
  line-height: 1.8;
}

.link-box-arrow .arrow {
  transform: translateX(0px);
  transition: all .5s ease;
  display: inline-block;
}

.link-box-arrow:hover .arrow {
  transform: translateX(4px);
}

.link-box-arrow .bottom-line {
  border-bottom-color: #036967;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

@media screen and (max-width: 991px) {
  .link-box-arrow {
      border-bottom-width: 2px;
      border-bottom-style: solid;
  }
  .link-box-arrow .bottom-line {
      border-bottom-width: 0px;
  }
}

@media screen and (max-width: 479px) {
  .link-box-arrow {
      text-decoration: underline;
  }
  .link-box-arrow .bottom-line {
      border-bottom-width: 0px !important;
  }
  .link-box-arrow {
      border-bottom-width: 0px !important;
  }
}

@media only screen and (max-width: 700px) {
  .w-iframe,
  iframe {
      width: 100% !important;
  }
}

@media only screen and (max-width: 700px) {
  .w-iframe,
  iframe {
      width: 100% !important;
  }
}

.w-slider-dot {
  background-color: #AAC3C5 !important;
  height: 15px;
  width: 15px;
}

.w-slider-dot.w-active {
  background-color: #0E788F !important;
}

.hover:hover {
  transition: all .5s ease;
}

.hover {
  transition: all .5s ease;
}

.hover:hover .play-icon-class path {
  stroke: #ffffff;
  transition: all .5s ease;
}

.hover .play-icon-class path {
  transition: all .5s ease;
}

.hover:hover .btn-hover {
  color: #fff;
}

.arrow-hov {
  transition: all .5s ease;
}

.arrow-hov:hover {
  opacity: .8;
  transition: all .5s ease;
}

.arrow-hov .arrow {
  transform: translateX(0px);
  transition: all .5s ease;
}

.arrow-hov:hover .arrow {
  transform: translateX(4px);
  transition: all .5s ease;
}

.menu-link-hover:hover .p-hover {
  color: #E0A62C !important;
  transition: all .5s ease;
}

.p-link {
  font-weight: 500;
}

.p-link:hover {
  color: #E0A62C !important;
}

.nav-hover:hover {
  color: #E0A62C !important;
  transition: all .5s ease;
}

.nav-hover {
  transition: all .5s ease;
}

.w--open img {
  opacity: 0;
}

.link-text {
  font-weight: 300 !important;
  color: #191919 !Important;
  text-decoration: none;
}

.paragraph-59:hover .p-link {
  color: #E0A62C !important;
}

a:hover .p-hover {
  color: #E0A62C !important;
  border: 2px solid #e0a62c !important;
  transition: all .5s ease !important;
  border-width: 0 0 2px 0 !important;
}
.d-body{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}